<template>
  <div class="lcr-paiement-cdg mt-4">
    <button
      size="sm"
      class="button-export-style"
      title="Générer les tranches de paiement"
      v-show="showEchButton"
      @click.prevent.stop="openLcrModal"
    >
      <font-awesome-icon icon="plus" class="icon-plus" />
    </button>
    <b-modal
      ref="paiement-modal"
      title="Générer les tranches de paiement"
      size="lg"
      no-close-on-backdrop
      :hide-footer="true"
      modal-class="modalLcrClass"
    >
      <!-- <template #modal-header>
        <h5>Générer les tranches de paiement</h5>
      </template> -->
      <Card>
        <template v-slot:body>
          <b-tabs content-class="mt-3" fill class="mt-1 tabs-class-reglement">
            <b-tab :title="'Générer les tranches de paiement'" active>
              <div class="row">
                <div class="col-6">
                  <div class="row mt-2 mb-2">
                    <div class="col-4">
                      <div class="label-item">Partenaire</div>
                      <div class="label-item">Société</div>
                      <div class="label-item">Devise</div>
                      <div class="label-item">Montant</div>
                      <div class="label-item">Date de début des échéances</div>
                    </div>
                    <div class="col-7 line-left">
                      <div class="item-label-value">
                        {{ getDataLcr.partenaire }}
                      </div>
                      <div class="item-label-value">
                        {{ getDataLcr.societe }}
                      </div>
                      <div class="item-label-value">
                        {{ getDataLcr.devise }}
                      </div>
                      <div class="item-label-value">
                        {{ getDataLcr.montant_ttc }}
                      </div>
                      <div class="item-label-value">
                        <date-picker
                          value-type="format"
                          format="DD-MM-YYYY"
                          type="date"
                          v-model="getDataLcr.date_echeance"
                          class="inputDatePaiement"
                          @input="changeChequeNumber"
                          :clearable="false"
                        ></date-picker>
                      </div>
                      <div
                        class="item-label-value error-message"
                        v-if="error && error.date != null"
                      >
                        <span class="error"> {{ error.date }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5 mb-2">
                    <div class="col-4">
                      <div class="label-item">A l'ordre de</div>
                      <div class="label-item">Valeur en</div>
                    </div>
                    <div class="col-7 line-left">
                      <div class="item-label-value">
                        <b-form-input
                          autocomplete="off"
                          v-model="getDataLcr.order_de"
                        ></b-form-input>
                      </div>
                      <div class="item-label-value">
                        <b-form-input
                          autocomplete="off"
                          v-model="getDataLcr.valeur_en"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="row mt-5 mb-2">
                    <div class="col-4">
                      <div class="label-item">Type de période *</div>
                      <div class="label-item">Nombre d'échéance *</div>
                      <div class="label-item">Fréquence d'échéance *</div>
                    </div>
                    <div class="col-7 line-left">
                      <div class="item-label-value">
                        <b-form-select
                          id="periode"
                          v-model="getDataLcr.type_de_periode"
                          :options="[
                            { value: 'Jour', text: 'Jour' },
                            { value: 'Semaine', text: 'Semaine' },
                            { value: 'Mois', text: 'Mois' }
                          ]"
                          @change="changeChequeNumber"
                        ></b-form-select>
                      </div>
                      <div class="item-label-value">
                        <b-form-input
                          min="1"
                          type="number"
                          autocomplete="off"
                          v-model="getDataLcr.echeance"
                          @input="changeChequeNumber"
                          oninput="validity.valid||(value < 1 || value.length==0? value = 1 : value =value);"
                        ></b-form-input>
                      </div>
                      <div class="item-label-value">
                        <b-form-input
                          min="0"
                          type="number"
                          autocomplete="off"
                          v-model="getDataLcr.frequence"
                          @input="changeChequeNumber"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5 mb-2">
                    <div class="col-4">
                      <div class="label-item">
                        RIB du tiré Compte bancaire du tiré
                      </div>
                      <div class="label-item">Banque du tiré</div>
                      <div class="label-item">Code banque</div>
                      <div class="label-item">Code guichet</div>
                      <div class="label-item">N de compte</div>
                      <div class="label-item">Clé RIB</div>
                    </div>
                    <div class="col-7 line-left">
                      <div class="item-label-value d-flex">
                        <multiselect
                          v-model="getDataLcr.rib"
                          placeholder="Rechercher"
                          track-by="bank_num_compte"
                          label="bank_num_compte"
                          :options="getRibsPartenaireLcr"
                          :searchable="true"
                          select-label=""
                          :max-height="150"
                          class="selectDefault"
                          :allow-empty="true"
                          :loading="geTribsPartenaireLcrLoader"
                        >
                          <span slot="noResult"
                            >Aucun {{ 'RIB ' }} trouvé
                          </span>
                          <template slot="option" slot-scope="{ option }"
                            ><strong>{{ option.bank_num_compte }} </strong>
                          </template>
                          <template slot="singleLabel" slot-scope="{ option }"
                            ><strong>{{ option.bank_num_compte }} </strong>
                          </template>
                          <span slot="noOptions">La liste est vide</span>
                        </multiselect>
                        <button
                          size="sm"
                          class="button-export-style ml-2"
                          title="Actualiser les données de la banque"
                          @click.prevent.stop="handleRefreshDataBank"
                        >
                          <font-awesome-icon icon="retweet" class="icon-plus" />
                        </button>
                      </div>

                      <div class="item-label-value">
                        {{
                          getDataLcr.rib && getDataLcr.rib.bank
                            ? getDataLcr.rib.bank
                            : ''
                        }}
                      </div>
                      <div class="item-label-value">
                        {{
                          getDataLcr.rib && getDataLcr.rib.code_bank
                            ? getDataLcr.rib.code_bank
                            : ''
                        }}
                      </div>
                      <div class="item-label-value">
                        {{
                          getDataLcr.rib && getDataLcr.rib.code_guichet
                            ? getDataLcr.rib.code_guichet
                            : ''
                        }}
                      </div>
                      <div class="item-label-value">
                        {{
                          getDataLcr.rib && getDataLcr.rib.num_compte
                            ? getDataLcr.rib.num_compte
                            : ''
                        }}
                      </div>
                      <div class="item-label-value">
                        {{
                          getDataLcr.rib && getDataLcr.rib.cle_rib
                            ? getDataLcr.rib.cle_rib
                            : ''
                        }}
                      </div>
                      <div
                        class="item-label-value error-message"
                        v-if="error && error.rib != null"
                      >
                        <span class="error"> {{ error.rib }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="'Visualiser les tranches de paiement '">
              <b-table-simple
                class="
                  table-fiche
                  m-0
                  p-0
                  d-flex
                  justify-content-center
                  block-details-injection
                  tableInjection
                "
                responsive
              >
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">Libellé</b-th>
                    <b-th class="newcolor">Numéro</b-th>
                    <b-th class="newcolor">Date de création</b-th>
                    <b-th class="newcolor">Date d'échéance</b-th>
                    <b-th class="newcolor">Client/Fournisseur</b-th>
                    <b-th class="newcolor">Devise</b-th>
                    <b-th class="newcolor">Banque du tiré</b-th>
                    <b-th class="newcolor">Montant</b-th>
                    <b-th class="newcolor">Date d'envoi</b-th>
                    <b-th class="newcolor">Date de signature</b-th>
                    <b-th class="newcolor">Date valeur</b-th>
                  </b-tr>
                  <template v-for="(item, index) in getDataLcr.lcr">
                    <b-tr :key="'eche' + index">
                      <b-td class="newcolor">{{ item.libelle }}</b-td>
                      <b-td class="newcolor">-</b-td>
                      <b-td class="newcolor">{{ item.date_creation }}</b-td>
                      <b-td class="newcolor">
                        <date-picker
                          v-model="item.date"
                          value-type="format"
                          format="DD-MM-YYYY"
                          type="date"
                          required
                          input-class="custom-date-picker-filter"
                          class="custom-date-picker-calender-filter mt-2 mb-2"
                        ></date-picker>
                      </b-td>
                      <b-td class="newcolor">{{ getDataLcr.partenaire }}</b-td>
                      <b-td class="newcolor">{{ getDataLcr.devise }}</b-td>
                      <b-td class="newcolor">{{
                        getDataLcr.rib && getDataLcr.rib.bank
                          ? getDataLcr.rib.bank
                          : ''
                      }}</b-td>
                      <b-td class="newcolor">
                        <b-form-input
                          min="0"
                          :max="getDataLcr.montant_ttc"
                          type="number"
                          autocomplete="off"
                          v-model="item.value"
                          @input="changeMontantEcheance(index)"
                        ></b-form-input>
                      </b-td>
                      <b-td class="newcolor">-</b-td>
                      <b-td class="newcolor">-</b-td>
                      <b-td class="newcolor">-</b-td>
                    </b-tr>
                  </template>
                  <b-tr v-if="getDataLcr.lcr.length > 0">
                    <b-td class="newcolor">-</b-td>
                    <b-td class="newcolor">-</b-td>
                    <b-td class="newcolor">-</b-td>
                    <b-td class="newcolor">-</b-td>
                    <b-td class="newcolor">-</b-td>
                    <b-td class="newcolor">-</b-td>
                    <b-td class="newcolor">-</b-td>
                    <b-td class="newcolor"
                      >{{ computedTotalEch }} {{ getDataLcr.devise }}</b-td
                    >
                    <b-td class="newcolor">-</b-td>
                    <b-td class="newcolor">-</b-td>
                    <b-td class="newcolor">-</b-td>
                  </b-tr>
                  <b-tr v-if="getDataLcr.lcr.length == 0">
                    <b-td colspan="11">
                      Il n'y a aucune tranche à afficher</b-td
                    >
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-tab>
          </b-tabs>
          <div class="actionModel d-flex justify-content-center mt-4">
            <b-button class="button-valide-style" @click="paiementLCR">
              <span>
                Valider les tranches de paiement
                <div class="loading ml-2"></div>
              </span>
            </b-button>
          </div>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    reglement: { required: true },
    factures: { required: true },
    montantFix: {
      default: false
    }
  },
  data() {
    return {
      getDataLcr: {
        montant_ttc: null,
        partenaire: null,
        client_id: null,
        societe: null,
        devise: null,
        date_echeance: null,
        order_de: 'Nous-même',
        valeur_en: null,
        type_de_periode: 'Mois',
        echeance: 1,
        frequence: 1,
        rib: null,
        beneficiaire_id: null,
        lcr: []
      },
      error: {
        rib: null,
        date_echeance: null
      }
    };
  },
  methods: {
    ...mapActions(['fetchRibsPartenaireLcr']),
    async handleRefreshDataBank() {
      const response = await this.fetchRibsPartenaireLcr(
        this.factures[0].client_id
      );
      if (response) {
        if (this.getDataLcr.rib) {
          this.getDataLcr.rib = this.getRibsPartenaireLcr.filter(
            i => i.id == this.getDataLcr.rib.id
          )[0];
        }
      }
    },
    openLcrModal() {
      if (
        this.montantFix == true &&
        this.factures.filter(i => i.check == true).length > 0
      ) {
        let vendeur = this.factures.filter(i => i.check == true)[0];

        this.getDataLcr.societe = vendeur.vendeur;
        this.getDataLcr.beneficiaire_id = vendeur.vendeur_id;
      } else {
        this.getDataLcr.societe = this.factures[0].vendeur;
        this.getDataLcr.beneficiaire_id = this.factures[0].vendeur_id;
      }
      this.fetchRibsPartenaireLcr(this.factures[0].client_id);
      if (this.getDataLcr.montant_ttc != this.computedTotalFacture) {
        this.getDataLcr.montant_ttc = this.computedTotalFacture;
        this.changeChequeNumber(true);
      }
      //   this.getDataLcr.date_echeance = moment().format('DD-MM-YYYY');
      this.getDataLcr.devise = this.reglement.unite;
      //   if (this.reglement.type_partenaire == 'Client') {
      //     this.getDataLcr.client_id = this.reglement.filiale_id;
      //     this.getDataLcr.partenaire = this.partenaire;
      //   } else {
      this.getDataLcr.client_id = this.factures[0].client_id;
      this.getDataLcr.partenaire = this.factures[0].client;
      //   }

      this.$refs['paiement-modal'].show();
    },
    paiementLCR() {
      this.error = { rib: null, date_echeance: null };
      if (this.getDataLcr.rib == null) {
        this.error.rib = 'RIB obligatoire';
      } else if (this.getDataLcr.date_echeance == null) {
        this.error.date = "date d'échéance obligatoire";
      } else {
        this.$emit('saveDataForLcr', this.getDataLcr);
        this.$refs['paiement-modal'].hide();
      }
    },
    handleChangeEcheance() {
      if (this.getDataLcr.echeance < 1) {
        this.getDataLcr.echeance = 1;
      }
      this.changeChequeNumber();
    },
    changeChequeNumber(verif) {
      if (verif === true && this.getDataLcr.lcr.length > 0) {
        this.getDataLcr.lcr.forEach((element, index) => {
          this.getDataLcr.lcr[index].value =
            this.getDataLcr.montant_ttc / this.getDataLcr.lcr.length;
          if (index == this.getDataLcr.echeance - 1 && index > 0) {
            this.getDataLcr.lcr[index].value = (
              this.getDataLcr.montant_ttc -
              (this.getDataLcr.montant_ttc / this.getDataLcr.echeance).toFixed(
                2
              ) *
                (this.getDataLcr.echeance - 1)
            ).toFixed(2);
          }
        });
      } else {
        this.getDataLcr.lcr = [];
        for (let i = 0; i < this.getDataLcr.echeance; i++) {
          let ech = {
            value: (
              this.getDataLcr.montant_ttc / this.getDataLcr.echeance
            ).toFixed(2),
            date: null,
            date_creation: moment().format('DD-MM-YYYY'),
            libelle: 'Tranche ' + (i + 1)
          };
          if (i == this.getDataLcr.echeance - 1 && i > 0) {
            ech.value = (
              this.getDataLcr.montant_ttc -
              (this.getDataLcr.montant_ttc / this.getDataLcr.echeance).toFixed(
                2
              ) *
                (this.getDataLcr.echeance - 1)
            ).toFixed(2);
          }

          if (
            this.getDataLcr.date_echeance != null &&
            this.getDataLcr.frequence != null
          ) {
            if (i == 0) {
              ech.date = this.getDataLcr.date_echeance;
            } else {
              if (this.getDataLcr.type_de_periode) {
                let periodeType =
                  this.getDataLcr.type_de_periode == 'Jour'
                    ? 'days'
                    : this.getDataLcr.type_de_periode == 'Semaine'
                    ? 'weeks'
                    : 'months';
                if (
                  moment(this.getDataLcr.lcr[i - 1].date, 'DD-MM-YYYY').format(
                    'M'
                  ) == 2 &&
                  moment(this.getDataLcr.lcr[i - 1].date, 'DD-MM-YYYY').format(
                    'D'
                  ) == 28
                ) {
                  let date =
                    '01-03-' +
                    moment(
                      this.getDataLcr.lcr[i - 1].date,
                      'DD-MM-YYYY'
                    ).format('Y');
                  ech.date = moment(date, 'DD-MM-YYYY')
                    .add(parseFloat(this.getDataLcr.frequence), periodeType)
                    .format('DD-MM-YYYY');
                } else {
                  ech.date = moment(
                    this.getDataLcr.lcr[i - 1].date,
                    'DD-MM-YYYY'
                  )
                    .add(parseFloat(this.getDataLcr.frequence), periodeType)
                    .format('DD-MM-YYYY');
                }
              }
            }
            this.getDataLcr.lcr.push(ech);
          }
        }
      }
    },
    changeMontantEcheance(index) {
      let sum = 0;
      let index_update =
        index == this.getDataLcr.lcr.length - 1
          ? 0
          : this.getDataLcr.lcr.length - 1;

      let index_start = index == this.getDataLcr.lcr.length - 1 ? 1 : 0;
      let index_end =
        index == this.getDataLcr.lcr.length - 1
          ? this.getDataLcr.lcr.length
          : this.getDataLcr.lcr.length - 1;
      this.getDataLcr.lcr.forEach((element, index) => {
        if (index >= index_start && index < index_end) {
          sum = parseFloat(sum) + parseFloat(element.value);
        }
      });
      this.getDataLcr.lcr[index_update].value = (
        this.getDataLcr.montant_ttc - sum
      ).toFixed(2);
    }
  },
  computed: {
    ...mapGetters([
      'clientsReglement',
      'TypeReglement',
      'getRibsPartenaireLcr',
      'geTribsPartenaireLcrLoader'
    ]),
    showEchButton() {
      return (
        (this.computedPaiementNameSelected == 'LCR' ||
          this.computedPaiementNameSelected == 'LCR Lettre de change Relevé' ||
          this.computedPaiementNameSelected.includes('LCR')) &&
        this.reglement &&
        this.reglement.filiale_id &&
        ((this.montantFix == true && this.reglement.montant_a_payer != null) ||
          (this.montantFix == false &&
            this.factures.filter(i => i.check == true).length > 0))
      );
    },
    computedMontantRest() {
      let montant = 0;
      for (let i = 0; i < this.getDataLcr.lcr.length; i++) {
        montant += parseFloat(this.getDataLcr.lcr[i].value);
      }
      if (parseFloat(montant) < parseFloat(this.getDataLcr.montant_ttc))
        return this.getDataLcr.montant_ttc - montant;
      return 0;
    },
    computedCheckNumLCR() {
      return this.getDataLcr.lcr.filter(item => item.numero);
    },
    computedPaiementNameSelected() {
      if (this.reglement && this.reglement.type_reglement != null) {
        let fund = this.TypeReglement.filter(
          i => i.id == this.reglement.type_reglement
        );
        return fund && fund.length ? fund[0].name : null;
      }
      return this.reglement.type_reglement + '';
    },
    computedTotalEch() {
      let sum = 0;
      this.getDataLcr.lcr.forEach(element => {
        sum = parseFloat(sum) + parseFloat(element.value);
      });
      sum = sum.toFixed(2);
      return sum;
    },
    computedTotalFacture() {
      let sum = 0;
      if (this.montantFix == false) {
        this.factures.forEach(element => {
          sum = parseFloat(sum) + parseFloat(element.montant_a_regle);
        });
      } else {
        sum = this.reglement.montant_a_payer;
      }

      sum = parseFloat(sum).toFixed(2);
      return sum;
    }
    // partenaire() {
    //   if (this.reglement && this.reglement.filiale_nom) {
    //     return this.reglement.filiale_nom;
    //   } else {
    //     let fund = this.clientsReglement.filter(
    //       i => i.id == this.reglement.filiale_id
    //     );
    //     if (fund.length) {
    //       return fund[0].name;
    //     } else {
    //       return '';
    //     }
    //   }
    // }
  },
  watch: {
    resetModalLcr: function() {
      this.error = { rib: null, date: null };
      this.getDataLcr = {
        montant_ttc: null,
        partenaire: null,
        client_id: null,
        societe: null,
        devise: null,
        date_echeance: null,
        order_de: 'Nous-même',
        valeur_en: null,
        type_de_periode: 'Mois',
        echeance: 1,
        frequence: 1,
        rib: null,
        beneficiaire_id: null,
        lcr: []
      };
    }
  },
  components: {
    Card: () => import('@/views/component/card.vue')
  }
};
</script>

<style lang="scss" scoped>
.lcr-paiement-cdg {
  .icon-plus {
    height: 11px;
    width: 11px;
    margin-bottom: 0px;
  }
}
</style>
<style lang="scss">
.modalLcrClass {
  .line-left {
    border-left: 1px solid rgb(181, 176, 176);
  }
  .label-item {
    text-align: left;
    font-weight: 600;
  }
  .item-label-value {
    text-align: left;
  }
  .item-label-value,
  .label-item {
    font-size: 12px;
    height: 33px;
  }
  .inputDatePaiement {
    width: 100% !important;
    .mx-input-wrapper {
      width: 100%;
    }
  }
  .multiselect__single {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: 9px;
    margin-bottom: 3px;
  }
  .multiselect__placeholder {
    margin-bottom: 6px;
  }
  .multiselect__tags {
    min-height: 30px;
  }
  input {
    height: 29px;
  }
}
</style>
